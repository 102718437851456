body {
  color: #404040;
  background: white;
  font-size: 1rem;
}

a:link,
a:visited {
  color: #222;
}

nav {
  background-attachment: fixed;
  background-color: #7e4e7e;
  margin: 0;
}
nav * {
  margin-bottom: 0;
}
nav li {
  padding: 0.5rem 2rem;
  color: floralwhite;
}
nav li.logo a:link,
nav li.logo a:visited,
nav li.logo a:hover {
  font-size: 1.2rem;
  color: floralwhite;
}

.hero {
  margin: 0 auto;
  background: #9575cd;
  color: floralwhite;
  background: url("/assets/images/shared/purple-fountain-grass1024x512.jpg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

/* grid for FlowerDetails */
.detailsGrid {
  display: grid;
  grid-gap: 1rem 2rem;
  grid-template-columns: 300px 275px 150px;
}

/* quantity styles */
.minus {
  position: absolute;
  background-image: url(/assets/icons/minus.svg);
  background-position: center;
  top: 45%;
  left: 25%;
  width: 50%;
  height: 10%;
}
.plus {
  position: absolute;
  background-image: url(/assets/icons/plus.svg);
  background-position: center;
  top: 25%;
  right: 25%;
  width: 50%;
  height: 50%;
}
/* remove spinner on input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* remove spinner on input */
input[type="number"] {
  -moz-appearance: textfield;
}
