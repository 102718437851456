@tailwind base;

@tailwind components;

@tailwind utilities;

@responsive {
  .addedToCart {
    top: 50%;
    left: 50%;
    margin-left: -140px;
    margin-top: -190px;
    width: 280px;
    height: 380px;
  }
}
@responsive {
  .addedToCartWide {
    top: 50%;
    left: 50%;
    margin-left: -162;
    margin-top: -210px;
    width: 324px;
    height: 420px;
  }
}

@page {
  size: auto;
  margin: 2cm;
}
